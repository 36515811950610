import { createApp } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia } from "pinia";
import apolloClient from "@/plugins/apollo";
import router from "@/router";
import App from "@/App";
import Vue3Progress from "vue3-progress";
import Shimmer from "vue3-shimmer";

/* flowbite */
import "flowbite";

/* tailwindcss */
import "@/style.css";
import "@/assets/css/tailwind.output.css";

/* phone */
import Vu3PhoneNumberInput from "vue3-phone-number-input";
import "vue3-phone-number-input/dist/vue3-phone-number-input.css";

/* vue select */
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

/* layouts */
import defaultLayout from "@/layouts/default";
import loggedInVendorLayout from "@/layouts/loggedInVendor";
import loggedInAgencyLayout from "@/layouts/loggedInAgency";

// ngprogress
const ngprogress_options = {
	position: "fixed",
	height: "10px",
	color: "#05505c",
};

/* RFx inventory */
const increasesQty = [
	"INITIAL",
	"GRN",
	"REVERSE_AUCTION",
	"ADD",
	"RETURN",
	"TRANSFER_IN",
];

createApp(App)
	.provide(DefaultApolloClient, apolloClient)
	.provide("increasesQty", increasesQty)
	.use(createPinia())
	.use(router)
	.use(Vu3PhoneNumberInput, {
		placeholder: "11 111 11 11",
		countryPlaceholder: "Search",
		countryLebel: "Select Country",
	})
	.use(Vue3Progress, ngprogress_options)
	.use(Shimmer)
	.component("v-select", vSelect)
	.component("default", defaultLayout)
	.component("loggedInVendor", loggedInVendorLayout)
	.component("loggedInAgency", loggedInAgencyLayout)
	.mount("#app");